<template>
  <div style="width: 100%; height: 100%">
    <div id="container"></div>
    <div class="input-card" style="width: 100%; height: 100%">
      <div style="height: 100%; overflow: auto">
        <h4 style="font-weight: bold; text-align: center">节点信息</h4>
        <div>
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              icon="el-icon-bottom"
              type="primary"
              size="mini"
              :timestamp="activity.nodeTime"
            >
              {{ activity.nodeName }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div>
          <el-button style="text-align: center" type="primary" v-if="canJump" size="small" @click="jumpMap">车辆轨迹</el-button>
        </div>
        <h4 style="font-weight: bold; text-align: center">详情明细</h4>
        <div class="myContentList">
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">司机:</div>
                <div style="float: left">{{ nodeDetails.driverName }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">车牌:</div>
                <div style="float: left">{{ nodeDetails.truckNo }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">司机电话:</div>
                <div style="float: left">{{ nodeDetails.driverMobile }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">货物信息:</div>
                <div style="float: left">
                  <el-tag size="mini">{{ nodeDetails.tioQty }}件</el-tag> <el-tag size="mini">{{ nodeDetails.tioWeight }}KG</el-tag>
                  <el-tag size="mini">{{ nodeDetails.tioVolume }}m³</el-tag>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">发车地点:</div>
                <div style="float: left">{{ nodeDetails.sendStratAddress }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">终止地点:</div>
                <div style="float: left">{{ nodeDetails.sendEndAddress }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-button style="text-align: center" type="primary" size="small" @click="handleReceive" v-if="nodeDetails.transStatus === 8">签收</el-button>
      </div>
    </div>
    <!-- 签收弹框 -->
    <el-dialog :visible.sync="transportVisible" width="95%" v-if="transportVisible">
      <trans-port :orderNo="$route.query.orderNo"></trans-port>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="handleConfirm">确认</el-button>
        <el-button type="primary" @click="transportVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import TransPort from './transportpp.vue'

export default {
  components: {
    TransPort,
  },
  mounted() {
    this.ampStateTrajectory()
  },
  beforeDestroy() {
    this.map && this.map.destroy()
  },
  data() {
    return {
      map: null,
      nodeDetails: {
        driverName: '',
        truckNo: '',
        driverMobile: '',
        sendStratTime: '',
        sendStratAddress: '',
        sendEndTime: '',
        sendEndAddress: '',
        tioQty: '',
        tioWeight: '',
        tioVolume: '',
      },
      transportVisible: false,
      activities: [],
    }
  },
  computed: {
    canJump() {
      const last = this.activities.at(-1)
      return last ? ['送达', '派送'].includes(last.nodeName) : false
    },
  },
  methods: {
    ampStateTrajectory() {
      this.getAmapList('A', this.$route.query.orderNo)
    },
    handleReceive() {
      this.transportVisible = true
    },
    jumpMap() {
      if (this.canJump) {
        window.open(`#/publicMapTrack_oRa_phone?orderNo=${this.$route.query.orderNo}&insertState=A`)
      }
    },
    handleConfirm() {
      this.$confirm('是否确认签收', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'btn-custom-cancel',
      })
        .then(() => {
          // 确认
          axios
            .get(`${this.$apiPath}/api/urban/permit/transport/signBill`, {
              params: {
                orderNo: this.$route.query.orderNo || this.orderNo,
              },
            })
            .then(res => {
              this.$message({
                type: res.data.success === true ? 'success' : 'error',
                message: res.data.success === true ? '确认收货成功' : res.data.msg,
                // type: 'success',
                // message: '签收成功',
              })
              if (res.data.success !== true) return

              this.transportVisible = false
            })
            .catch(err => {
              console.error(err)
            })
        })
        .catch(() => {})
    },
    getAmapList(stats, getData) {
      const data = new URLSearchParams()
      data.append('orderNo', getData)
      axios({
        method: 'get',
        url: `${this.$apiPath}/api/urban/permit/tioNode/getNodes?orderNo=` + this.$route.query.orderNo,
        data: data,
      }).then(res => {
        // console.log(res.data.success);
        if (res.data.success == true) {
          if (res.data.data.tioVolume == null || res.data.data.tioVolume == 'null') {
            res.data.data.tioVolume = '0'
          }
          if (res.data.data.tioWeight == null || res.data.data.tioWeight == 'null') {
            res.data.data.tioWeight = '0'
          }
          if (res.data.data.tioQty == null || res.data.data.tioQty == 'null') {
            res.data.data.tioQty = '0'
          }
          this.nodeDetails = res.data.data
          const nodeTimes = res.data.data.nodeTimes
          for (let i = 0; i < nodeTimes.length; i++) {
            if (nodeTimes[i].nodeTime == null) {
            } else {
              nodeTimes[i].nodeTime = this.$moment(nodeTimes[i].nodeTime).format('YYYY-MM-DD HH:mm:ss')
            }
          }
          this.activities = res.data.data.nodeTimes
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
        }
      })
    },
  },
}
</script>
​
<style lang="scss">
.btn-custom-cancel {
  float: right;
  margin-right: 40%;
  margin-left: 10px;
}
</style>

​
<style scoped>
@import url('https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css');

#container {
  height: 100%;
  width: 100%;
}

.input-card {
  margin-right: 0rem;
  width: 9rem;
}

.myContentList {
  margin-bottom: 5px;
  font-size: 12px;
}

.grid-content {
  font-size: 14px;
}

/deep/.el-dialog__footer {
  text-align: center;
}
</style>
